<template>
    <div class="headerWrap">
        <div class="headerBox">
            <div class="headerLeft">
                <a href="#" @click="$router.push('/')" style="max-width: 300px">
                    <img v-if="logoUrl.logo" :src="'https://' + logoUrl.logo" alt="" />

                    <strong v-else style="color: #333; font-size: 16px; margin-right: 15px">
                        <span v-if="logoShow">
                            <img src="@/assets/images/logomr.png" alt=""
                                style=" float:left;margin-right: 15px;height: 32px">
                            <p class="fl"
                                style="align-items: center;display: flex;height: 40px;max-width: 250px; line-height: 20px">
                                {{ logoUrl.name ? logoUrl.name : '中公优效邦' }}</p>
                        </span>
                    </strong>
                </a>
                <ul>
                    <li :class="nowActive == item.id ? 'active' : ''" v-for="(item, index) in tabList" :key="index"
                        @click="changeTab(item)">
                        {{ item.name }}
                    </li>
                </ul>
                <div class="selectInputWrap">
                    <el-form autocomplete="off">
                        <el-input placeholder="请输入关键字..." v-model="searchText" class="input-with-select"
                            @keyup.enter.native="searchclick()">
                            <el-select v-model="selectInput" slot="prepend" placeholder="请选择">
                                <el-option label="课程" :value="1"></el-option>
                                <el-option label="试题" :value="2"></el-option>
                            </el-select>
                            <el-button slot="append" icon="iconfont icon-sousuo"
                                @click.enter="searchclick()"></el-button>
                        </el-input>
                    </el-form>
                </div>
            </div>
            <div class="headerRight">
                <!-- <i
					class="iconfont icon-tz navMessageIcon"
					@click="jumpMessage"
				>
				</i> -->
                <span v-if="siteid != 46" class="fontSize16 mr30" style="width:230px; text-align: right">{{ school_name
                    }}</span>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <el-avatar :src="img ? img : require('@/assets/images/head_default.png')"
                            icon="el-icon-user-solid"></el-avatar>
                        <i class="iconfont icon-xl ml5"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in userList" :key="index"
                            @click.native="jumpUser(item)">{{ item.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { logout } from '@/views/components/assets/api.js';
import { delCookie, getCookie } from '../../../assets/js/cookie';
export default {
    watch: {
        $route: {
            handler: function (val, o) {
                console.log(val, o);
                this.nowActive = val.meta.type
                    ? val.meta.type
                    : val.query.metaType;
            },
            deep: true,
        },
    },
    data () {
        return {
            logoShow: false,
            school_name: '',
            tabList: [
                {
                    id: 1,
                    name: '首页',
                    path: '/home',
                },
                {
                    id: 2,
                    name: '学习',
                    path: '/',
                },
                {
                    id: 3,
                    name: '练题',
                    path: '/tiku',
                },
                {
                    id: 4,
                    name: '公告',
                    path: '/notice',
                },
            ],
            nowActive: 1,
            searchText: '',
            selectInput: 1,
            img: '',
            userList: [
                {
                    id: 1,
                    name: '个人信息',
                    path: '/archives_student',
                },
                // {
                //   id: 2,
                //   name: "我的学分",
                //   path: "/credithour",
                // },
                // {
                //   id: 3,
                //   name: "我的证书",
                //   path: "/certificate",
                // },
                {
                    id: 4,
                    name: '反馈建议',
                    path: '/feedback',
                },
                {
                    id: 5,
                    name: '账号设置',
                    path: '/account',
                },
                {
                    id: 6,
                    name: '退出登录',
                },
            ],
            logoUrl: '',
            siteid: ''
        };
    },
    mounted () {
        this.siteid = getCookie('siteid')
        setTimeout(() => {
            if (sessionStorage.getItem('schoolInfo')) {
                this.logoUrl = JSON.parse(sessionStorage.getItem('schoolInfo'))
            }
        }, 500);
        this.nowActive = this.$route.meta.type;
        if (
            this.$route.query.searchText != undefined ||
            this.$route.query.searchText != ''
        ) {
            this.searchText = this.$route.query.searchText;
        }
        if (sessionStorage.getItem('infos')) {
            this.img = JSON.parse(sessionStorage.getItem('infos')).head_img;
            this.school_name = JSON.parse(sessionStorage.getItem('infos')).school_name;

        }
        window.addEventListener('setItem', (event) => {
            if (event.key === 'infos') {
                this.img = JSON.parse(event.newValue).head_img;
                this.school_name = JSON.parse(event.newValue).school_name
            }
            if (event.key === 'schoolInfo') {
                this.logoUrl = JSON.parse(event.newValue).logo
            }
        });
    },
    updated () {
        setTimeout(() => {
            let hostname = window.location.hostname;
            if (hostname == 'edu.ipku.com') {
                this.logoShow = false
            } else {
                this.logoShow = true
            }
        }, 2000);

    },
    methods: {
        changeTab (item) {
            this.$router.push(item.path);
        },
        jumpMessage () {
            this.$router.push('/message');
            // window.location.href = "http://localhost:8080/#/message?identity=3";
        },
        jumpUser (item) {
            if (item.path) {
                this.$router.push(item.path);
                // window.location.href =
                //   "http://localhost:8080/#/" + item.path + "?identity=3";
            } else {
                //退出登录
                logout().then((res) => {
                    if (res.code == 0) {
                        sessionStorage.removeItem('infos');
                        sessionStorage.removeItem('course');
                        sessionStorage.removeItem('str_column');
                        sessionStorage.removeItem('column_id');
                        delCookie('siteid');
                        delCookie('infos');
                        delCookie('faculty_id');
                        if (window.location.href.indexOf('localhost') > -1) {
                            window.location.href = 'http://localhost:8080/login/#/';
                        } else {
                            let hostname = window.location.hostname;
                            window.location.href =
                                'https://' + hostname + '/login/#/';
                        }

                    }
                });
            }
        },
        searchclick () {
            if (this.selectInput == 1) {
                window.sessionStorage.removeItem('meta');
                this.$router.push({
                    path: '/search',
                    query: {
                        searchText: this.searchText,
                    },
                });
            } else {
                this.$router.push({
                    path: '/tiku_search',
                    query: {
                        searchText: this.searchText,
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import './assets/header.scss';
</style>
