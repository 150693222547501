<template>
	<div class="haveHeaderWrap">
		<XHeader ref="" v-if="xmFlag"></XHeader>
		<template v-else>
			<Header ref="Header" v-if="hosttype"></Header>
		</template>
		<div class="mainWrap" :style="{ paddingTop: hosttype ? '2px' : '0' }">
			<router-view />
		</div>
	</div>
</template>

<script>
import Header from '@/views/components/header/header.vue';
import XHeader from '@/views/components/header/xheader.vue';
import { getCookie } from '@/assets/js/cookie.js';
export default {
	components: {
		Header,
    XHeader
	},
	data() {
		return {
			hosttype: true,
			xmFlag: false,
		};
	},
	mounted() {
		if (this.$route.query.hosttype == 'pcclient') {
			this.hosttype = false;
		}
		if (getCookie('siteid') == 52||getCookie('siteid') == 56) {
			this.xmFlag = true;
		}
	},
	methods: {},
};
</script>

<style scoped lang="scss">
.haveHeaderWrap {
	width: 100%;
	height: 100%;
}
.mainWrap {
	padding: 0px 0 12px;
	height: 100%;
  margin-top:76px;
	box-sizing: border-box;
}
</style>
